import { Trans, useTranslation } from "react-i18next";
//import { useTranslation } from "react-i18next";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Link } from "../components/Link";
//import { ActionButton } from "../components/LinkButton";
import { Picture, IMAGES } from "../components/Picture";
import { Typography } from "../components/Typography";

export const Travel = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Section>
          <Typography as="h1">{t("travel")}</Typography>
          <Line />
          <Typography>{t("travelPage.venue.body")}</Typography>
          <br />
          <br />
          <Typography isBold>{t("travelPage.venue.address")}</Typography>
          <br />
          <br />
          <Typography>{t("travelPage.venue.rooms")}</Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[5]} />
        </Section>
      </Layout>

      <Layout>
        <Section>
          <Typography as="h1">{t("Travel Grants")}</Typography>
          <Line />
          <Typography>
            <Trans
              i18nKey="travelPage.grants.body"
              components={{
                a: (
                  <Link
                    to={"mailto:siamsc@maths.ox.ac.uk"}
                  />
                ),
              }}
            />
          </Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[6]} />
        </Section>
      </Layout>

      <Layout>
        <Section>
          <Typography as="h1">{t("Accommodation")}</Typography>
          <Line />
		      <Typography>
            <Trans
              i18nKey="travelPage.accommodation.body1"
              components={{
                a: (
                  <Link
                    to={"https://www.maths.ox.ac.uk/about-us/travel-maps"}
                  />
                ),
              }}
            />
          </Typography>
		      <br />
          <br />
		      <Typography>
            <Trans
              i18nKey="travelPage.accommodation.body2"
              components={{
                a: (
                  <Link
                    to={"https://www.oxfordbus.co.uk/services/OXBC/300"}
                  />
                ),
              }}
            />
          </Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[7]} />
        </Section>
      </Layout>

      {/* <Layout>
        <Section>
          <Typography as="h2">{t("travelPage.hotel.title")}</Typography>
          <Line />
          <Typography>{t("travelPage.hotel.body")}</Typography>
          <br />
          <br />
          <Typography isBold>
            {t("travelPage.hotel.reservation.header")}
          </Typography>
          <br />
          <br />
          <Typography isBold>
            {t("travelPage.hotel.reservation.phone.what") + " "}
          </Typography>
          <Typography>
            {t("travelPage.hotel.reservation.phone.details")}
          </Typography>
          <br />
          <br />
          <Typography isBold>
            {t("travelPage.hotel.reservation.online.what") + " "}
          </Typography>
          <Typography>
            <Trans
              i18nKey="travelPage.hotel.reservation.online.details"
              components={{
                a: <Link to="https://book.passkey.com/e/50310056" />,
              }}
            />
          </Typography>
          <br />
          <br />
          <Typography isItalic>
            {t("travelPage.hotel.reservation.extra")}
          </Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[1]} />
        </Section>
      </Layout> */}

      {/* <Layout>
        <Section>
          <Typography as="h2">{t("travelPage.air.title")}</Typography>
          <Line />
          <Typography>
            <Trans
              i18nKey="travelPage.air.body"
              components={{
                i: <Typography isItalic />,
                a: (
                  <Link to="https://www.aircanada.com/ca/en/aco/home/plan/special-assistance/meetings-and-conventions.html" />
                ),
              }}
            ></Trans>
          </Typography>
          <br />
          <br />
          <Typography isBold>{t("travelPage.air.code")}</Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[4]} />
        </Section>
      </Layout> */}

      {/* <Layout>
        <Section>
          <Typography as="h2">{t("travelPage.local.title")}</Typography>
          <Line />
          <Typography>
            <Trans
              components={{
                a1: <Link to="https://goo.gl/maps/KBgJZGPKWaEtdTiZ7" />,
                b: <Typography isBold />,
                a2: <Link to="https://www.upexpress.com/" />,
                a3a: <Link to="http://www.ttc.ca/" />,
                a3b: (
                  <Link to="https://www.ttc.ca/subway-stations/union-station" />
                ),
                a4: <Link to="https://www.gotransit.com/en/" />,
              }}
            >
              {[1, 2, 3, 4, 5]
                .map((n) => t(`travelPage.local.body.${n}`))
                .join("\n\n")}
            </Trans>
          </Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.ttc[2]} />
        </Section>
      </Layout> */}

      {/* <Layout>
        <Section>
          <Typography as="h2">{t("travelPage.tourist.title")}</Typography>
          <Line />
          <Typography>{t("travelPage.tourist.body")}</Typography>
          <br />
          <br />
          <ActionButton to="https://www.destinationtoronto.com/">
            {t("travelPage.tourist.cta")}
          </ActionButton>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[5]} />
        </Section>
      </Layout> */}
    </>
  );
};
