import { Link as ReactRouterLink } from "react-router-dom";

export interface LinkProps {
  to: string;
  children?: React.ReactNode;
}

export const Link = ({ to, children, ...rest }: LinkProps) =>
  to.startsWith("http") || to.startsWith("mailto")  ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <ReactRouterLink to={to} {...rest}>
      {children}
    </ReactRouterLink>
  );
