/* eslint-disable jsx-a11y/anchor-has-content */
import { Fragment } from "react";
import styled from "styled-components";
//import { Trans, useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Typography } from "../components/Typography";
import { Layout, Section } from "../components/Layout";
import { IMAGES, Picture } from "../components/Picture";
import { Line } from "../components/Line";
import { Link } from "../components/Link";
import { ActionButton } from "../components/LinkButton";
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../components/style";
import { getLocalizedPath } from "../services/locale";

const SponsorLink = styled(Link)`
  align-self: center;

  img {
    max-width: 90%;
    max-height: 90%;
  }
`;

const DATE_KEYS = ["absOpen", "regOpen", "absClose", "grantClose", "regClose", "conf"] as const;

const GRID_SPACING = "25px";

const PartnerLogoContainer = styled.div`
  display: grid;
  padding-top: ${GRID_SPACING};
  gap: ${GRID_SPACING};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
  }
`;

export const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Section data-testid="landing-content">
          <Typography as="h1" data-testid="header">
            {t("NAC2022")}
          </Typography>
          <Line />
          <Typography as="h3" data-testid="date-and-location">
            {t("july7to10")} | {t("toronto")}
          </Typography>
          <br />
          <Typography data-testid="info">{t("home.mainInfo")}</Typography>
          <br />
          <br />
          {/* <Typography data-testid="fee-and-limit">
            {t("home.cost")} | {t("home.competitorLimit")}
          </Typography> */}
          <br />
          <br />
          {/* <ActionButton
            to="https://www.worldcubeassociation.org/competitions/NAC2022/results/podiums#e333"
            data-testid="results-cta"
          >
            {t("results")}
          </ActionButton> */}
        </Section>
        <Section>
          <Picture
            nativeAspectRatio
            data-testid="main-logo"
            src="/oxford-siam-logo.png"
            alt="" // the same information is present in text outside the logo, so alt text can be empty
          />
        </Section>
      </Layout>

      <Layout>
        <Section data-testid="location">
          <Typography as="h1">{t("home.hostCity.title")}</Typography>
          <Line />
          <Typography>{t("home.hostCity.body")}</Typography>
          <br />
          <br />
          <Typography>{t("home.hostCity.competitionVenue")}</Typography>
          <br />
          <br />
          <Typography>{t("home.hostCity.address")}</Typography>
          <br />
          <br />
          <ActionButton
            to={getLocalizedPath("en", "travel")}
            data-testid="travel-cta"
          >
            {t("moreInfo")}
          </ActionButton>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[3]} />
        </Section>
      </Layout>

      <Layout>
        <Section data-testid="key-dates">
          <Typography as="h1">{t("home.keyDates.title")}</Typography>
          <Line />
          {DATE_KEYS.map((k) => (
            <Fragment key={k}>
              <Typography isBold>
                {t(`home.keyDates.${k}.when`) + " "}
              </Typography>
              <Typography>{t(`home.keyDates.${k}.what`) + "\n\n"}</Typography>
            </Fragment>
          ))}
          {/*<Typography isItalic>
            <Trans
              i18nKey="home.keyDates.qualificationInfo"
              components={{
                a: (
                  <Link
                    to={getLocalizedPath("en", "events")}
                    data-testid="qualification-link"
                  />
                ),
              }}
            />
          </Typography>*/}
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[2]} />
        </Section>
      </Layout>

      {/*<Layout>
        <Section data-testid="our-partners">
          <Typography as="h1">{t("ourSponsors")}</Typography>
          <Line />
          <Typography>{t("home.ourSponsorsBody")}</Typography>
          <PartnerLogoContainer data-testid="logo-container">


            <SponsorLink to="https://www.siam.org/" data-testid="siam">
              <img src="/siam-logo.png" alt="SIAM" />
            </SponsorLink>
            <Typography>{t("home.siamCaption")}</Typography>

            <SponsorLink to="https://www.gresearch.co.uk/" data-testid="gresearch">
              <img src="/g-research-logo.jpg" alt="G-Research" />
            </SponsorLink>
            <Typography>{t("home.gresearchCaption")}</Typography>

            <SponsorLink to="https://www.ox.ac.uk/" data-testid="oxford">
            <img src="/oxford-logo.png" alt="University of Oxford" />
            </SponsorLink>
            <Typography>{t("home.oxfordCaption")}</Typography>

            <SponsorLink to="https://ima.org.uk/" data-testid="ima">
            <img src="/ima-logo.png" alt="IMA" />
            </SponsorLink>
            <Typography>{t("home.imaCaption")}</Typography>


          </PartnerLogoContainer>
        </Section>
        <Section>
          <Picture id={IMAGES.skyline[1]} />
        </Section>
      </Layout>*/}
    </>
  );
};
