import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";
import { LOCALES, getLocalizedPath } from "../services/locale";
import { useTranslation } from "react-i18next";
import { Link } from "./Link";
import { Typography } from "./Typography";
import { COLORS, MOBILE_BREAKPOINT, flexColumn, flexRow } from "./style";
import { FOOTER_ROUTES, SOCIAL_LINKS } from "./constants";

const FooterBase = styled.div`
  background-color: ${COLORS.BLACK};
  padding: 30px 60px;

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 30px 15px;
  }
`;

//const Notice = styled.div`
//  text-align: center;
//  margin-bottom: 1em;
//  @media (max-width: ${MOBILE_BREAKPOINT}) {
//    padding: 0 8%;
//  }
//`;

const FooterContent = styled.div`
  ${flexRow}
  justify-content: space-between;

  .partners {
    flex: 4;
    ${flexRow}

    .partner-logos {
      ${flexRow}
      a {
        margin-left: 30px;
      }
    }
  }

  .nav-link-grid {
    flex: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;

    a {
      text-decoration-color: ${COLORS.WHITE};
    }
  }

  .social-links {
    flex: 1;
    span {
      margin-bottom: 0.5rem;
      display: block;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    ${flexColumn}

    .social-links,
    .nav-link-grid {
      align-self: center;
      margin-bottom: 30px;
    }

    .social-links {
      ${flexRow}

      span {
        margin-right: 20px;
        margin-bottom: 0;
        display: unset;
      }
    }

    .partners {
      ${flexColumn}
      width: 100%;
      align-items: center;

      .our-partners {
        margin-bottom: 20px;
      }

      .partner-logos {
        ${flexColumn}
        align-items: center;
        margin: 0;
        a {
          margin-left: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

const SocialLink = styled.a`
  margin-right: 12px;
`;

interface Props {
  currentLocale: keyof typeof LOCALES;
}

export const Footer = ({ currentLocale }: Props) => {
  const { t } = useTranslation();

  return (
    <FooterBase>
      {/* <Notice>
        <Typography isItalic color={COLORS.WHITE} data-testid="rubiks-notice">
          {t("rubiksTm")}
        </Typography>
      </Notice> */}
      {/* <Notice>
        <Typography
          isItalic
          color={COLORS.WHITE}
          data-testid="canada-lands-notice"
        >
          {t("canadaLandsNotice")}
        </Typography>
      </Notice> */}
      <FooterContent>
        {/*<div className="partners" data-testid="partners">
          <div className="partner-logos">
          <Link to="https://www.siam.org/" data-testid="siam">
              <img src="/siam-logo.png" alt="SIAM" height={80} />
            </Link>
            <Link to="https://www.gresearch.co.uk/" data-testid="gresearch">
              <img src="/g-research-logo.jpg" alt="G-Research" height={80} />
            </Link>
            <Link to="https://www.ox.ac.uk/" data-testid="oxford">
              <img src="/oxford-logo.png" alt="University of Oxford" height={80} />
            </Link>
            <Link to="https://ima.org.uk/" data-testid="ima">
              <img src="/ima-logo.png" alt="IMA" height={80} />
            </Link>
          </div>
        </div>*/}
        {/*<div className="social-links" data-testid="social-links">
          <Typography color={COLORS.WHITE}>{t("followUs")}</Typography>
          {SOCIAL_LINKS.map(({ to, name, Component }) => (
            <SocialLink
              key={name}
              href={to}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={name}
              data-testid={name}
            >
              <Component
                width={24}
                height={24}
                title={name}
                fill={COLORS.WHITE}
              />
            </SocialLink>
          ))}
        </div>*/}
        <div className="nav-link-grid" data-testid="nav-links">
          {FOOTER_ROUTES.map((route) => (
            <ReactRouterLink
              key={route}
              to={getLocalizedPath(currentLocale, route)}
            >
              <Typography color={COLORS.WHITE}>{t(route)}</Typography>
            </ReactRouterLink>
          ))}
        </div>
      </FooterContent>
    </FooterBase>
  );
};
