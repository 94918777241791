import styled from "styled-components";
import { MENU_HEIGHT } from "./Menu";
import { PAGE_BASE_VERTICAL_PADDING } from "./PageBase";
import { MOBILE_BREAKPOINT } from "./style";

const LayoutBase = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
`;

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return <LayoutBase>{children}</LayoutBase>;
};

export const Section = styled.div`
  flex: 1;
  margin: 0 10px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    padding-bottom: 50px;
  }
`;

export const Centered = styled.div<{
  $flexDirection?: "row" | "column";
}>`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection ?? "column"};
  justify-content: center;
  align-items: center;
  min-height: calc(
    100vh - ${MENU_HEIGHT}px - (2 * ${PAGE_BASE_VERTICAL_PADDING}px)
  );
`;
