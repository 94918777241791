import styled from "styled-components";
import { COLORS, MOBILE_BREAKPOINT } from "./style";

export const Table = styled.table`
  width: 100%;
  border: ${COLORS.BLACK} solid 0.5px;
  border-spacing: 0;

  td {
    text-align: center;
    border: ${COLORS.BLACK} solid 0.5px;
    padding: 10px 5px;
  }

  abbr {
    cursor: help;
  }
`;

export const TableContainer = styled.div`
  margin: 30px 0;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    overflow-x: scroll;
  }
`;

export const TableData = styled.td`
  min-width: 200px;
`;
