import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { LinkProps } from "./Link";
import { COLORS } from "./style";

const linkButtonStyle = css`
  display: inline-block;
  text-decoration: none;
  font-size: 1.4rem;
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  padding: 0.75rem 1.25rem;
  border: none;
  cursor: pointer;
  outline-color: ${COLORS.RED};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${COLORS.GREY};
  }
`;

const ExternalLinkButton = styled.a`
  ${linkButtonStyle}
`;

const InternalLinkButton = styled(Link)`
  ${linkButtonStyle}
`;

export const LinkButton = ({ to, children, ...rest }: LinkProps) =>
  to.startsWith("http") ? (
    <ExternalLinkButton
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </ExternalLinkButton>
  ) : (
    <InternalLinkButton to={to} {...rest}>
      {children}
    </InternalLinkButton>
  );

export const ActionButton = styled(LinkButton)`
  margin-top: 20px;
`;
