import { ReactComponent as Twitter } from "./icons/twitter.svg";
import { ReactComponent as Facebook } from "./icons/facebook.svg";
import { ReactComponent as Instagram } from "./icons/instagram.svg";

// const EXCLUDED_FROM_MENU_ROUTES = new Set(["registration"]);
const EXCLUDED_FROM_MENU_ROUTES = new Set();

export const FOOTER_ROUTES = [
  "registration",
  // "rubiks-cup",
  // "groups",
  // "events",
  "schedule",
  // "covid-19",
  "travel",
  // "staff",
  "seminars",
//  "faq",
];

export const MENU_ROUTES = FOOTER_ROUTES.filter(
  (r) => !EXCLUDED_FROM_MENU_ROUTES.has(r)
);

export const SOCIAL_LINKS = [
  {
    name: "Facebook",
    Component: Facebook,
    to: "https://www.facebook.com/oxfordsiamsc/",
  },
  {
    name: "Twitter",
    Component: Twitter,
    to: "https://twitter.com/",
  },
  {
    name: "Instagram",
    Component: Instagram,
    to: "https://instagram.com/",
  },
];

export const STAGES = {
  Y: "Yellow Stage",
  B: "Blue Stage",
  O: "Orange Stage",
  R: "Red Stage",
  G: "Green Stage",
};

export const ROOMS = {
  METRO: "Metropolitan Ballroom",
  FRONTENAC: "Frontenac Ballroom",
};
