import styled from "styled-components";

const Image = styled.img<{ $nativeAspectRatio: boolean }>`
  object-fit: cover;
  width: 100%;
  aspect-ratio: ${(props) =>
    props.$nativeAspectRatio ? undefined : "16 / 10"};
`;

export const IMAGES = {
  skyline: {
    1: "TAxS43K2MUE",
    2: "BgerTyN9pIM",
    3: "hv6MvllZ0lU",
    4: "LrTt009Yf-k",
    5: "Jrq8ONPnrIU",
	6: "d55fhArDES0",
	7: "ZK5UNAWzQgs",
  },
  canal: {
    1: "fQoVtqiM60g",
    2: "gOw7taURSTA",
  },
} as const;

const ALT_TEXT_MAP: Record<string, string> = {
  "TAxS43K2MUE": "View over city",
  "BgerTyN9pIM": "Christchurch flower garden",
  "hv6MvllZ0lU": "The Bridge of Sighs",
  "LrTt009Yf-k": "Busts by Radcliffe Camera",
  "Jrq8ONPnrIU": "An Oxford college",
  "fQoVtqiM60g": "Canal boat",
  "gOw7taURSTA": "Bloom by the canal",
  "d55fhArDES0": "Building",
  "ZK5UNAWzQgs": "Building",
};

interface PictureProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  id?: string;
  nativeAspectRatio?: boolean;
}

export const Picture = ({
  id,
  nativeAspectRatio = false,
  ...props
}: PictureProps) => {
  if (typeof id === "string" && id.length > 0) {
    return (
      <Image
        $nativeAspectRatio={nativeAspectRatio}
        src={`https://source.unsplash.com/${id}`}
        alt={ALT_TEXT_MAP[id]}
        {...props}
      />
    );
  }

  return <Image $nativeAspectRatio={nativeAspectRatio} {...props} />;
};
