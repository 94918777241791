import { Fragment, useEffect, useState, useRef, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LOCALES, getLocalizedPath } from "../services/locale";
import * as flags from "../flags";
import { MENU_ROUTES, SOCIAL_LINKS } from "./constants";
import { Typography } from "./Typography";
import {
  COLORS,
  MOBILE_BREAKPOINT,
  flexColumn,
  flexRow,
  DESKTOP_BREAKPOINT,
  MAIN_BREAKPOINT,
  SECONDARY_BREAKPOINT,
} from "./style";
import "./hamburger.css";

export const MENU_HEIGHT = 75;

const MOBILE_MENU_PADDING = "25px";

const MenuBase = styled.div`
  top: 0;
  position: sticky;
  background-color: ${COLORS.WHITE};

  ${flexRow}
  justify-content: space-between;

  .logo {
    ${flexRow}
  }
`;

const MenuLinks = styled.nav<{ $isOpen: boolean; $isMobile: boolean }>`
  background-color: ${COLORS.WHITE};

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    ${flexColumn}
    top: ${MENU_HEIGHT}px;
    position: absolute;
    width: 100vw;
    // fill available height (padding uses 2 * 25px, logo uses 75px)
    height: calc(100vh - 2 * ${MOBILE_MENU_PADDING} - ${MENU_HEIGHT}px);
    padding: ${(props) => (props.$isOpen ? MOBILE_MENU_PADDING : 0)};
    margin: 0;

    transition: ${(props) => props.$isMobile && "transform 0.3s"};
    transform: ${(props) => `translateX(${props.$isOpen ? 0 : "-100vw"})}`};

    .social-links {
      margin-top: auto;
    }

    .nav-links {
      ${flexColumn}
      .nav-link {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  @media (min-width: ${DESKTOP_BREAKPOINT}) and (max-width: ${SECONDARY_BREAKPOINT}) {
    .nav-links {
      max-width: calc(100vw - 500px);
      flex-wrap: wrap;
      row-gap: 5px;
    }
  }

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    ${flexRow}
    margin-right: 20px;
    padding: 0;

    .social-links,
    .nav-links {
      ${flexRow}
    }

    .social-links {
      margin-left: 25px;
    }

    .nav-links {
      .nav-link {
        font-size: 1rem;
        margin: 0 10px;
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: 0% 2px;
        transition: background-size 0.3s;

        &.inactive {
          &:hover,
          &:focus {
            background-size: 100% 2px;
          }
        }
      }
    }
  }

  .nav-links {
    .nav-link {
      text-decoration: none;

      &.active {
        color: ${COLORS.BLUE};
      }

      &.inactive {
        color: ${COLORS.BLACK};
      }
    }
  }
`;

// .locales {
//     .vl {
//       border-left: 2px solid ${COLORS.BLACK};
//       height: 1rem;
//     }
//     .locale-link {
//       text-transform: uppercase;
//       margin: 0 5px;
//     }
//   }

const SocialLink = styled.a`
  margin-right: 12px;
`;

const DateLocationContainer = styled.div<{ $isVisible: boolean }>`
  margin-left: 12px;
  transition: transform 0.3s;

  transform: ${(props) =>
    `translateY(${props.$isVisible ? "0px" : `-${MENU_HEIGHT}px`})}`};
`;

const LOCALE_CODE_TO_NAME = {
  [LOCALES.en]: "English",
  [LOCALES.es]: "Español",
  [LOCALES.fr]: "Français",
};

interface Props {
  currentLocale: keyof typeof LOCALES;
  currentPage: string | undefined;
}

export const Menu = ({ currentLocale, currentPage }: Props) => {
  const mobileMenu = useRef<HTMLDivElement>(null);
  const [isLocationDateVisible, setIsLocationDateVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < MENU_HEIGHT && isLocationDateVisible) {
        setIsLocationDateVisible(false);
      } else if (window.scrollY > MENU_HEIGHT + 25 && !isLocationDateVisible) {
        setIsLocationDateVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLocationDateVisible]);

  useLayoutEffect(() => {
    if (window.innerWidth <= MAIN_BREAKPOINT) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > MAIN_BREAKPOINT) {
        if (isMobileMenuOpen) {
          setIsMobileMenuOpen(false);
        }

        if (isMobile) {
          setIsMobile(false);
        }
      } else {
        if (!isMobile) {
          setIsMobile(true);
        }
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileMenuOpen, isMobile]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isMobileMenuOpen && mobileMenu.current !== null) {
        const focusable =
          mobileMenu.current.querySelectorAll<HTMLElement>("button, a");
        const firstFocusable = focusable[0];
        const lastFocusable = focusable[focusable.length - 1];

        if (e.key === "Tab") {
          if (e.shiftKey) {
            if (document.activeElement === firstFocusable) {
              lastFocusable.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusable) {
              firstFocusable.focus();
              e.preventDefault();
            }
          }
        } else if (e.key === "Esc" || e.key === "Escape") {
          setIsMobileMenuOpen(false);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isMobileMenuOpen]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMobileMenuOpen]);

  return (
    <MenuBase ref={mobileMenu}>
      <div className="logo">
        <Link to={getLocalizedPath(currentLocale)}>
          <img
            alt="Conference logo"
            src="/s-logo.png"
            height={MENU_HEIGHT}
            width={MENU_HEIGHT}
          />
        </Link>
        <DateLocationContainer $isVisible={isLocationDateVisible}>
          <Typography>{`${t("toronto")}\n${t("july7to10")}`}</Typography>
        </DateLocationContainer>
      </div>
      <MenuLinks $isOpen={isMobileMenuOpen} $isMobile={isMobile}>
        {(!isMobile || isMobileMenuOpen) && (
          <>
            <div className="nav-links">
              {MENU_ROUTES.map((route) => (
                <NavLink
                  key={route}
                  to={getLocalizedPath(currentLocale, route)}
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : "inactive"}`
                  }
                >
                  {t(route)}
                </NavLink>
              ))}
            </div>
            {/*<div className="social-links">
              {SOCIAL_LINKS.map(({ to, name, Component }) => (
                <SocialLink
                  key={name}
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Component
                    width={24}
                    height={24}
                    title={name}
                    fill={COLORS.BLACK}
                  />
                </SocialLink>
              ))}
            </div>*/}
            {flags.LOCALIZATION && (
              <div className="locales">
                {Object.values(LOCALES)
                  .filter((locale) => locale !== currentLocale)
                  .map((locale, index) => (
                    <Fragment key={locale}>
                      {index !== 0 && <div className="vl" />}
                      <Link
                        to={getLocalizedPath(locale, currentPage)}
                        className="locale-link"
                        title={LOCALE_CODE_TO_NAME[locale]}
                      >
                        {locale}
                      </Link>
                    </Fragment>
                  ))}
              </div>
            )}
          </>
        )}
      </MenuLinks>
      {isMobile && (
        <button
          className={`${
            isMobileMenuOpen && "is-active"
          } hamburger hamburger--spin hamburger-custom`}
          onClick={toggleMenu}
          aria-label={`${isMobileMenuOpen ? "close" : "open"} navigation menu`}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      )}
    </MenuBase>
  );
};
