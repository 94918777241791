import { useTranslation } from "react-i18next";
import { Typography } from "../components/Typography";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Table, TableContainer, TableData } from "../components/Table";
import { Link } from "../components/Link";

const SEMINARS = [
  {
    time: "Thursday 1 June, 2023",
    presenter: { webpage: "https://iris.ucl.ac.uk/iris/browse/profile?upi=ACPAG88", name: "Christina Pagel" },
    title: "Data, science and Covid: what went right and what went wrong",
  },
  {
    time: "Thursday 1 June, 2023",
    presenter: { webpage: "http://www.alhusseinfawzi.info/", name: "Hussein Fawzi" },
    title: "Discovering faster matrix multiplication algorithms with deep reinforcement learning",
  },
    {
    time: "Friday 2 June, 2023",
    presenter: { webpage: "https://www.birmingham.ac.uk/staff/profiles/maths/tzella-alexandra.aspx", name: "Alexandra Tzella" },
    title: "Advection, diffusion and geometry: a new description of scalar dispersion",
  },
  {
    time: "Friday 2 June, 2023",
    presenter: { webpage: "https://research-information.bris.ac.uk/en/persons/tanniemola-b-liverpool", name: "Tanniemola Liverpool" },
    title: "The mathematics of wound healing",
  },
] as const;

export const Seminars = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Section>
        <Typography as="h1">{t("seminarsPage.title")}</Typography>
        <Line />
        <Typography>{t("seminarsPage.body")}</Typography>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <td>
                  <Typography isBold>{t("seminarsPage.table.time")}</Typography>
                </td>
                <td>
                  <Typography isBold>
                    {t("seminarsPage.table.presenters")}
                  </Typography>
                </td>
                <td>
                  <Typography isBold>
                    {t("seminarsPage.table.title")}
                  </Typography>
                </td>
              </tr>
            </thead>
            <tbody>
              {SEMINARS.map((seminar) => (
                <tr key={seminar.title}>
                  <TableData>
                    <Typography>{seminar.time}</Typography>
                  </TableData>
                  <TableData>
				          <Typography key={seminar.presenter.webpage}>
                    <Link to={`${seminar.presenter.webpage}`}>{seminar.presenter.name}</Link>
                  </Typography>


                    {/*{seminar.presenter.map(
                      ({ name, webpage }, index, presenter) => (
                        <Typography key={webpage}>
                          <Link to={`${webpage}`}>{name}</Link>
                        </Typography>
                      )
                    )}*/}

                  </TableData>
                  <TableData>
                    <Typography>
                      {seminar.title}
                    </Typography>
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Section>
    </Layout>
  );
};
