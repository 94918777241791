import { css, createGlobalStyle } from "styled-components";

export const COLORS = {
  RED: "#da1f26",
  BLACK: "#08090d",
  WHITE: "#ffffff",
  ORANGE: "#ff7f50",
  BLUE: "#122f53",
  GREY: "#a79d96",
  GREEN: "#228b22",
} as const;

export const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MAIN_BREAKPOINT = 900;
export const MOBILE_BREAKPOINT = `${MAIN_BREAKPOINT}px`;
export const DESKTOP_BREAKPOINT = `${MAIN_BREAKPOINT + 1}px`;
export const SECONDARY_BREAKPOINT = "1200px";

export const FONT_FAMILY = "Lato, Arial, Helvetica, sans-serif";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${FONT_FAMILY};
  }
`;
