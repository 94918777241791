import React from "react";
import styled from "styled-components";
import { COLORS } from "./style";

interface TypographyProps {
  as?: "h1" | "h2" | "h3" | "span";
  color?: string;
  isBold?: boolean;
  isItalic?: boolean;
  className?: string;
  id?: string;
  children?: React.ReactNode;
}

const TypographyBase = (props: Required<TypographyProps>) => {
  const { as: Component, className, children, ...rest } = props;
  return (
    <Component className={className} {...rest}>
      {children}
    </Component>
  );
};

const fontSizeMap = {
  h1: "3rem",
  h2: "2rem",
  h3: "1.5rem",
  span: "1.25rem",
} as const;

const fontWeightMap = {
  h1: "bold",
  h2: "bold",
  h3: "bold",
  span: "normal",
} as const;

const StyledTypography = styled(TypographyBase)`
  margin: 0;
  padding: 0;
  font-size: ${(props) => fontSizeMap[props.as]};
  font-weight: ${(props) =>
    typeof props.isBold === "boolean"
      ? props.isBold
        ? "bold"
        : "normal"
      : fontWeightMap[props.as]};
  font-style: ${(props) => (props.isItalic ? "italic" : "normal")};
  color: ${(props) => props.color ?? COLORS.BLACK};
  white-space: pre-wrap;
`;

export const Typography = (props: TypographyProps) => {
  const { as = "span", children, ...rest } = props;
  return (
    <StyledTypography as={as} {...rest}>
      {children}
    </StyledTypography>
  );
};
